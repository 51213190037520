<template>
  <v-sheet class="customer">
    <v-card-text class="px-0">

      <v-row>

        <v-col cols="12" md="12" class="py-0">
          <v-layout class="">
            <v-col md="4" class="" v-for="(status, index) in status_list" :key="`span-loop-${index}`">
              <v-card @click="statusSelect(status.dbvalue)" class="mx-auto"
                :style="`border: 0.5px solid ${getBgColor(status.value)}`">
                <v-card-actions>
                  <v-list-item class="grow px-0">
                    <v-list-item-content>
                      <v-list-item-title class="custom-form-label me-0" style="font-weight: 600; font-size: 18px">

                        <VAvatar v-if="status.value == 'all'" :size="55" rounded color="blue lighten-5" variant="tonal"
                          class="me-3">{{ total_stocks }}
                          <v-icon size="34" :style="`color : ${getAvatarColor(status.value)}`" dark>person</v-icon>
                        </VAvatar>
                        <VAvatar v-else-if="status.value == 'active'" :size="55" rounded color="green lighten-5"
                          variant="tonal" class="me-3">
                          <v-icon size="34" :style="`color : ${getAvatarColor(status.value)}`" dark>person</v-icon>
                        </VAvatar>
                        <VAvatar v-else :size="55" rounded color="red lighten-5" variant="tonal" class="me-3">{{
                          deactivated_quantity }}
                          <v-icon size="34" :style="`color : ${getAvatarColor(status.value)}`" dark>person</v-icon>
                        </VAvatar>
                        {{ status.description }}
                      </v-list-item-title>
                    </v-list-item-content>
                    <div align="center" justify="end">
                      <span class="subheading" v-if="status.value == 'all'">
                        <v-chip color="#0D47A1" style="font-size: 18px; color: white; font-weight: 600">{{
                          status.totalSuppliers
                        }}</v-chip>
                      </span>
                      <span class="subheading" v-else-if="status.value == 'active'">

                        <v-chip color="#4CAF4E" style="font-size: 18px; color: white; font-weight: 600">{{
                          status.supplier_status_count }}</v-chip>
                      </span>
                      <span class="subheading" v-else>
                        <v-chip color="#E53935" style="font-size: 18px; color: white; font-weight: 600">{{
                          status.supplier_status_count }}</v-chip>
                      </span>
                    </div>
                  </v-list-item>
                </v-card-actions>
              </v-card>
            </v-col>
          </v-layout>
        </v-col>
        <v-col md="3" class="mt-3">
          <SupplierHeader moduleType="supplier" :dataLoading="dataLoading" :stat="selectstatus" :status-list="status_list"
            allkey="all_purchase" countkey="status_count" v-on:saveAddress="updateAddressPerson"></SupplierHeader>
        </v-col>
        <v-col cols="12" md="6">
        </v-col>
        <v-col md="3" class="mt-5 justify-content-end">
          
          <v-flex class="d-flex justify-content-end">
            
            <AutoCompleteInput   hide-details :disabled="pageLoading" :hideTopMargin="true" :loading="pageLoading" v-on:change="search"
              :items="['January', 'February', 'March', 'April', 'May', 'June', 'July', 'August', 'September', 'October', 'November', 'December']"
              id="segment" item-text="name"  style="width: 180px" class="mt-0" item-value="id" v-model="month" placeholder="Select Month">
            </AutoCompleteInput>
            <!-- <v-btn
                  color="blue darken-4"
                  depressed
                  tile
                  class="ml-2 white--text"
                  @click="importExcel"
                >
                  Import Excel
                </v-btn> -->
            <v-btn :disabled="pageLoading" color="blue darken-4" depressed tile class="ml-2 white--text me-2"
              :to="{ name: 'create-supplier', query: { t: new Date().getTime() } }">
              <v-icon>mdi-plus</v-icon> Create
            </v-btn>
            <v-menu offset-y left transition="slide-y-transition" :close-on-content-click="false">
              <template v-slot:activator="{ on: menu, attrs }">
                <v-tooltip left>
                  <template v-slot:activator="{ on: tooltip }">
                    <v-btn color="blue darken-4" depressed tile v-bind="attrs" v-on="{ ...tooltip, ...menu }"
                      class="white--text">
                      <v-icon>edit_note</v-icon>
                    </v-btn>
                  </template>
                  <span>Columns arrangement and visibility</span>
                </v-tooltip>
              </template>
              <v-list nav dense>
                <v-list-item draggable @dragstart="dragstart(index)" @dragover="dragover(index)" @dragend="dragEnd"
                  v-for="(item, index) in defaultColDefs" :key="item.field + '_' + index"
                  class="flex-column column-arrangement-list-item cursor-move">
                  <div class="d-flex flex-row">
                    <v-checkbox :ripple="false" hide-details color="#0d47a1" v-model="defaultColShow"
                        v-bind:value="item.field" :disabled="Number(item.fixed) || pageLoading"
                        v-on:change="updateVisible()" class="m-0"></v-checkbox>
                    <v-list-item-title>{{ item.headerName }}</v-list-item-title>
                    <v-icon>drag_indicator</v-icon>
                  </div>
                  <v-divider v-if="index < customThead.length - 1" class="mt-1 mb-0"
                    style="width: 100%"></v-divider>
                </v-list-item>
              </v-list>
            </v-menu>
            <!-- <v-btn
              :disabled="pageLoading"
              color="blue darken-4"
              depressed
              tile
              class="ml-2 white--text"
              :to="{ name: 'create-supplier', query: { t: new Date().getTime() } }"
            >
              <v-icon>mdi-export</v-icon>Export</v-btn
            > -->
          </v-flex>
        </v-col>
      </v-row>
    </v-card-text>
    <v-simple-table fixed-header class="bt-table table_height_supplier">
      <template v-slot:default>
        <thead>
          <tr>
            <th v-for="heading in customThead" :key="`_head_${heading.title}`" style="background-color: #f5f5f5">
              <div v-if="heading.status">
                <span v-if="heading.title === '#'">
                  <v-checkbox hide-details color="#0d47a1" class="m-0" v-model="supplier_selectedAll"></v-checkbox>
                </span>
                <span v-else> {{ heading.headerName }}</span>
              </div>
            </th>
          </tr>
        </thead>

        <tbody v-if="!pageLoading">
          <template v-if="customers?.length">
            <tr v-for="(row, index) in customers" :key="`$_${index + index * 5}_supplier_` + index"
              :class="index % 2 === 0 && 'blue lighten-5'" class="product-listing-tr" link v-on:click="routeToDetailSupplier(row)">
              <td v-for="(th, index) in customThead" :key="'key_' + index" class="cursor-pointer">
                <v-chip color="#4CAF50" v-if="th.field === 'date' && th.status"
                  style="color: white; font-weight: 600">
                  {{ row.id }}
                </v-chip>
                <div v-else-if="th.field === '#' && th.status">
                  <v-checkbox v-model="row.checkbox_status" color="#0D47A1"></v-checkbox>
                  <!-- v-model="row['status']" -->
                </div>
                <div v-else-if="th.field === 'action' && th.status">
                  <v-tooltip top content-class="custom-top-tooltip">
                    <template v-slot:activator="{ on, attrs }">
                      <v-btn depressed fab dark x-small class="mx-2" color="green" v-bind="attrs" v-on="on"
                        v-on:click.stop.prevent="routeToUpdate(row.id)">
                        <v-icon>mdi-pencil</v-icon>
                      </v-btn>
                    </template>
                    <span>Edit</span>
                  </v-tooltip>
                  <v-tooltip top content-class="custom-top-tooltip" v-if="!row.total">
                    <template v-slot:activator="{ on, attrs }">
                      <v-btn depressed fab dark x-small color="red" v-bind="attrs" v-on="on"
                        v-on:click.stop.prevent="deleteConfirm(row)">
                        <v-icon>mdi-delete</v-icon>
                      </v-btn>
                    </template>
                    <span>Delete</span>
                  </v-tooltip>
                </div>

                <div v-else-if="th.field === 'images' && th.status" link
                  v-on:click="routeToDetailSupplier(row)">
                  <ImageTemplate :src="row?.supplier_image?.url" :maxWidth="70" :maxHeight="70" :aspectRatio="1"
                    :contain="false" customClass="mr-2" circle></ImageTemplate>
                </div>
                <div v-if="th.field === 'status' && th.status" link v-on:click="routeToDetailSupplier(row)">
                  <p class="my-auto py-2">
                    <v-chip class="py-0" small outlined color="primary" label>
                      <ValueTemplate :value="row?.barcode" title="Code"></ValueTemplate>
                      <!-- {{ row[getColValue(th.title)]?.value[0].status_no }} -->
                    </v-chip>
                  </p>
                  <p>
                    <v-chip color="green" outlined small style="font-weight: 600" label>
                      <ValueTemplate title="Status" value="Active"></ValueTemplate>
                      <!-- {{ row[getColValue(th.title)]?.value[0].status_active }} -->
                    </v-chip>
                  </p>
                </div>
                <div v-else-if="th.field === 'company' && th.status" link
                  v-on:click="routeToDetailSupplier(row)">
                  <p class="my-auto py-0">
                    <v-icon>person</v-icon>
                    <ValueTemplate :value="row?.first_name" title="Company User"></ValueTemplate>
                    <!-- {{ row[getColValue(th.title)]?.value[0].company_user }} -->
                  </p>
                  <p class="my-auto py-0">
                    <v-icon>person</v-icon>
                    <ValueTemplate :value="row?.company_name" title="Company User"></ValueTemplate>
                    <!-- {{ row[getColValue(th.title)]?.value[0].company_user }} -->
                  </p>
                  <p class="my-auto py-0">
                    <v-icon>email</v-icon>
                    <ValueTemplate :value="row?.company_email" title="Enterprises"></ValueTemplate>
                    <!-- {{ row[getColValue(th.title)]?.value[0].testing_enterprises }} -->
                  </p>

                  <p class="my-auto py-0">
                    <v-icon>phone</v-icon>
                    <ValueTemplate :value="row?.company_phone" title="Number"></ValueTemplate>
                    <!-- {{ row[getColValue(th.title)]?.value[0].company_number }} -->
                  </p>
                </div>
                <div v-else-if="th.field === 'contact' && th.status" link
                  v-on:click="routeToDetailSupplier(row)">
                  <v-chip class="py-0" small color="green" style="color: white; font-weight: 600" label>
                    PRIMARY
                  </v-chip>
                  <p class="my-auto py-0">
                    <v-icon>person</v-icon>
                    <ValueTemplate :value="row?.persons_name" title="Title"></ValueTemplate>
                    <!-- {{ row[getColValue(th.title)]?.value[0].company_title }} -->
                  </p>
                  <p class="my-auto py-0">
                    <v-icon>email</v-icon>
                    <ValueTemplate :value="row.persons_email" title="Email"></ValueTemplate>
                    <!-- {{ row[getColValue(th.title)]?.value[0].company_email }} -->
                  </p>
                  <p class="my-auto py-0">
                    <v-icon>phone</v-icon>
                    <ValueTemplate :value="row.persons_phone" title="Number"></ValueTemplate>
                    <!-- {{ row[getColValue(th.title)]?.value[0].company_number }} -->
                  </p>
                  <!-- <v-chip small color="#0D47A1" style="color: white; font-weight: 600" label>
                    {{ row[getColValue(th.title)]?.value[0].oneMore_details }}
                  </v-chip> -->
                </div>

                <div v-else-if="th.field === 'category' && th.status" link
                  v-on:click="routeToDetailSupplier(row)">
                  <template v-if="row.category">
                    <ValueTemplate class="text-capitalize" :value="row.category" title="category"></ValueTemplate>
                  </template>
                </div>
                <div v-else-if="th.field === 'outstanding' && th.status" link
                  v-on:click="routeToDetailSupplier(row)">
                  <div>
                    <v-chip v-if="row.initial_pending" small outlined label
                      :color="row.amountStatus == 'Completed' ? 'success' : 'red'"> <b>Balance</b>: <span> <b>{{formatMoney(
                        row.initial_pending)}}</b></span></v-chip>
                    <!-- Balance: <span>{{ row.initial_pending }}</span> -->
                  </div>
                  <div class="ml-3">
                    <b>Total</b> : <span> <b>{{formatMoney( row.total)}}</b></span>
                  </div>
                  <div class="ml-3">
                    <b>Paid</b>: <span> <b>{{formatMoney( row.final_paid + row.initial_paid)}}</b></span>
                  </div>
                </div>

                <div v-else-if="th.field === 'created_at' && th.status" link
                  v-on:click="routeToDetailSupplier(row)">
                  <p class="my-auto py-2">
                    <v-chip class="py-0" small outlined color="primary" label>
                      <ValueTemplate :value="row.user_name" title="Admin"></ValueTemplate>
                      <!-- {{ row[getColValue(th.title)]?.value[0].createdBy_admin }} -->
                    </v-chip>
                  </p>
                  <p>
                    <v-chip small label style="font-weight: 600">
                      <!-- <ValueTemplate :value="row.formatDate" title="Created Date"></ValueTemplate> -->
                      {{ formatedateTimes(row.added_at) }}
                      <!-- {{ row[getColValue(th.title)]?.value[0].created_date }} -->
                    </v-chip>
                  </p>
                </div>
                <div v-else-if="th.field === 'updated_at' && th.status" link
                  v-on:click="routeToDetailSupplier(row)">
                  <template v-if="row.updated_at">
                    <!-- {{ formatedateTimes(row.updated_at) }} -->
                    <!-- <p class="my-auto py-2">
                      <v-chip class="py-0" small outlined color="primary" label>
                        <ValueTemplate :value="row[getColValue(th.title)]?.value[0].createdBy_admin"
                          title="Created by admin"></ValueTemplate>
                      </v-chip>
                    </p> -->
                    <p>
                      <v-chip small label style="font-weight: 600">
                        {{ formatedateTimes(row.updated_at) }}
                        <!-- {{ row[getColValue(th.title)]?.value[0].created_date }} -->
                      </v-chip>
                    </p>
                  </template>
                  <template v-else>
                    <p style="font-size: 16px; font-weight: 600">no modified</p>
                  </template>
                </div>
              </td>
            </tr>
          </template>
          <template v-else>
            <tr>
              <td colspan="9">
                <p class="m-0 text-center">
                  <img width="30" :src="$assetURL('media/error/empty.png')" class="row-not-found-image mr-4" />
                  Uhh... There are no supplier at the moment.
                </p>
              </td>
            </tr>
          </template>
        </tbody>
        <tfoot v-else>
          <tr v-for="idr in 9" :key="`_$idr_key_${idr}`">
            <td v-for="idk in 9" :key="`_$idk_key_${idk}`">
              <v-skeleton-loader type="text"></v-skeleton-loader>
            </td>
          </tr>
        </tfoot>
      </template>
    </v-simple-table>

    <v-layout v-if="this.customers?.length" class="light-border-top mt-4">
      <!-- {{ pageLimit }} -->
      <v-flex md6>
        <label class="btx-label p-4">Showing {{ showingFrom }} to {{ showingTo }} of {{ totalRows }} entries</label>
      </v-flex>
      <v-flex md6>
        <v-pagination color="blue darken-4" v-model="currentPage" :length="pageLimit" :total-visible="7"></v-pagination>
      </v-flex>
    </v-layout>
    <Dialog :dialog="dialog" :dialogWidth="900">
      <template v-slot:title>Desctiption</template>
      <template v-slot:body>
        <v-row class="delete-dialog">
          <v-col md="2" class="py-0 text-right my-auto">
            <span class="svg-icon svg-icon-lg delete-confirm-icon">
              <img width="70" height="70" src="https://cdn.pixabay.com/photo/2023/07/04/18/10/wreath-8106795_640.png" />
            </span>
          </v-col>
          <v-col md="10" class="py-0 my-auto">
            <p class="btx-p m-0"><b class="red--text text--lighten-1"></b>{{ text }}</p>
          </v-col>
        </v-row>
      </template>
      <template v-slot:action>
        <v-btn depressed tile @click="dialog = false"> Close </v-btn>
      </template>
    </Dialog>

    <OrderTemplate v-model="order" :customer-id="customerId" :dialog="orderDialog" v-on:close="orderDialog = false"
      v-on:save="saveOrder()" v-on:collect:voucher="getAvailableVouchers" v-on:claim:voucher="getCollectedVouchers">
    </OrderTemplate>
    <CollectTemplate :dialog="collectDialog" :order-id="cOrderId" :order-code="cOrderCode" :vouchers="cVouchers"
      v-on:close="vCollected()" v-on:collected="vCollected()"></CollectTemplate>
    <DeleteTemplate type="Supplier" :delete-text="deleteText" delete-note=""
      :delete-dialog="deleteDialog" :delete-endpoint="deleteEndpoint" v-on:close="deleteDialog = false"
      v-on:delete:success="getSummary()">
    </DeleteTemplate>
    <SearchTemplate :dialog="allCollectedDialog" v-on:close="allCollectedDialog = false"></SearchTemplate>
    <Dialog dense :dialog="whatsappPromotional" v-if="whatsappPromotional">
      <template v-slot:title> Whatsapp Promotional </template>
      <template v-slot:body>
        <div class="mx-6" fluid>
          <v-row class="mx-6">
            <v-col md="2">
              <label for="item-file-upload" class="btx-label mt-2 required">Template</label>
            </v-col>
            <v-col md="8">
              <SelectInput hide-details hideTopMargin :loading="pageLoading" :disabled="pageLoading" item-text="text"
                dense item-value="value" :items.sync="templateList" v-model="whatsapp.template"></SelectInput>
            </v-col>
          </v-row>
          <v-row class="mx-6">
            <v-col md="2">
              <label for="item-file-upload" class="btx-label mt-2 required">File</label>
            </v-col>
            <v-col md="8">
              <v-file-input v-model="whatsapp.files" ref="fileInput" hide-details id="item-file-upload"
                label="Upload Images" outlined dense></v-file-input>
            </v-col>
          </v-row>
        </div>
      </template>
      <template v-slot:action>
        <v-btn :disabled="pageLoading" :loading="pageLoading" class="white--text mr-2" depressed color="blue darken-4"
          tile @click="whatsappPromotional = false">
          Send
        </v-btn>
        <v-btn :disabled="pageLoading" depressed tile v-on:click="whatsappPromotional = false">
          Cancel
        </v-btn>
      </template>
    </Dialog>
    <ImportTemplate
          :dialog="importDialog"
          api-url="import-suppliers"
          title="Import Supplier"
          v-on:close="importDialog = false"
        ></ImportTemplate>
  </v-sheet>
</template>
<script>
import Dialog from "@/view/components/Dialog";
import { GET_CUSTOMERS, GET_TEMPLATE_LIST } from "@/core/lib/customer.lib";
import { SET_ERROR } from "@/core/services/store/common.module";
import DeleteTemplate from "@/view/components/DeleteTemplate";
import OrderTemplate from "@/view/components/OrderTemplate";
import SupplierHeader from "@/view/components/SupplierHeader";
import moment from "moment-timezone";

import CollectTemplate from "@/view/components/CollectVoucherTemplate";

import SearchTemplate from "@/view/components/SearchAllVouchers";
import ValueTemplate from "@/view/components/ValueTemplate";
import ImageTemplate from "@/view/components/Image";
import { SearchEventBus } from "@/core/lib/search.lib";
import { formatMoney } from "accounting-js";
import { POST } from "@/core/services/store/request.module";

import {
  SET_SEARCH_TITLE,
  SET_SEARCH_MODEL,
  SET_SEARCH_BAR,
  BEFORE_DESTROY,

} from "@/core/services/store/listing.module";
import { mapGetters } from "vuex";
import { format } from "date-fns";
import { GET_AVAILABLE_VOUCHERS, GET_COLLECTED_VOUCHERS } from "@/core/lib/common.lib";
import SelectInput from "@/view/components/SelectInput.vue";
import AutoCompleteInput from "@/view/components/AutoCompleteInput";
import ImportTemplate from "@/view/components/Import-Supplier-Template.vue";

import { filter, map } from "lodash";

export default {
  name: "supplier-listing",
  title: "Listing Supplier",
  data() {
    return {
      text: "Also called a company description, your business description summarizes what your company does, its purpose, and what makes it unique. It's a critical component of your business plan and immediately follows your executive summary",
      dialog: false,
      defaultColDefs: [],
      customThead: [],
      templateList: [{ text: "First", value: "first" }],
      template: "first",
      deactivated_quantity:null,
      stock:[],
      whatsapp: {
        files: [],
        template: "first",
      },
      status_list: [],
      month:null,
      searchEnabled: false,
      searchParam: null,
      dataLoading:false,
      supplier_selectedAll: false,
      filterType: "all",
      filterDateRange: null,
      speedDial: [],
      whatsappPromotional: false,
      customers: [],
      total_stocks:null,
      currentPage: 1,
      importDialog:false,
      pageLimit: 1,
      showingFrom: 0,
      showingTo: 0,
      selectstatus:null,
      totalRows: 0,
      pageLoading: true,
      deleteText: null,
      deleteEndpoint: null,
      deleteDialog: false,
      customerId: null,
      orderDialog: false,
      rOrderId: 0,
      rOrderCode: null,
      rLoading: false,
      rVouchers: [],
      redeemDialog: false,
      cOrderId: 0,
      cOrderCode: null,
      cLoading: false,
      cVouchers: [],
      collectDialog: false,
      allCollectedDialog: false,
      suppliertableHeading: [
        // { title: "#", status: true },
        { title: "ACTION", status: true },
        { title: "IMAGE", status: true },
        { title: "STATUS", status: true },
        { title: "COMPANY DETAILS", status: true },
        { title: "CONTACT PERSON", status: true },
        { title: "CATEGORY", status: true },
        { title: "OutStanding", status: true },
        { title: "CREATED AT", status: true },
        { title: "LAST MODIFIED AT", status: true },
      ],
      salesSummaryDate: [
        {
          id: 1,
          checkbox_status: false,
          code: "GHI789",
          select: { value: "", key: "SELECT" },
          action: { value: "", key: "ACTION" },
          image: {
            link: "https://images.unsplash.com/photo-1500648767791-00dcc994a43e?ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxzZWFyY2h8MTB8fHVzZXJzfGVufDB8fDB8fHww&auto=format&fit=crop&w=500&q=60",
          },
          status: {
            value: [
              {
                status_no: "S0018",
                status_active: "ACTIVE",
              },
            ],
            key: "STATUS",
          },
          company_details: {
            value: [
              {
                company_user: "testing enterprises display",
                testing_enterprises: "tesing enterprises",
                company_email: "bthrust@gmail.com",
                company_number: "",
                display_name: "bthrust testing display",
              },
            ],
            key: "COMPANY DETAILS",
          },
          contact_person: {
            value: [
              {
                _primary: "PRIMARY",
                company_title: "bthrush testing display",
                company_email: "bthrust@gmail.com",
                company_number: "+65-75757575",
                oneMore_details: "+1 more",
              },
            ],
            key: "CONTACT PERSON",
          },
          category: { value: "COMPUTERS & ELECTRIONICS", key: "CATEGORY" },
          created_at: {
            value: [
              {
                createdBy_admin: "SUPER ADMIN",
                created_date: "29/08/2023 05:54PM",
                updated_date: "",
                updated_by: "21/02/2023 03:14PM",
                created_by: "29/08/2023 05:54PM",
              },
            ],
            key: "CREATED AT",
          },
          last_modified_at: {
            value: [
              {
                createdBy_admin: "",
                created_date: "",
                updated_date: "21/02/2023 03:14PM",
                updated_by: "21/02/2023 03:14PM",
                created_by: "29/08/2023 05:54PM",
              },
            ],
            key: "LAST MODIFIED AT",
          },
        },
        {
          id: 2,
          checkbox_status: false,
          code: "MNG272",
          select: { value: "", key: "SELECT" },
          action: { value: "", key: "ACTION" },
          image: {
            link: "https://images.unsplash.com/photo-1472099645785-5658abf4ff4e?ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxzZWFyY2h8MTJ8fHVzZXJzfGVufDB8fDB8fHww&auto=format&fit=crop&w=500&q=60",
          },
          status: {
            value: [
              {
                status_no: "S0017",
                status_active: "INACTIVE",
              },
            ],
            key: "STATUS",
          },
          company_details: {
            value: [
              {
                company_user: "HAZEL",
                testing_enterprises: "Tri-Start Industries Pte Ltd",
                company_email: "sup@bt.com",
                company_number: "+65-96535164",
                display_name: "bthrust testing display",
              },
            ],
            key: "COMPANY DETAILS",
          },
          contact_person: {
            value: [
              {
                _primary: "PRIMARY",
                company_title: "bthrush testing display",
                company_email: "bthrust@gmail.com",
                company_number: "+65-98505334",
                oneMore_details: "+1 more",
              },
            ],
            key: "CONTACT PERSON",
          },
          category: { value: "MANUFACTURING, WHOLESALE, DISTRIBUTION", key: "CATEGORY" },
          created_at: {
            value: [
              {
                createdBy_admin: "SUPER ADMIN",
                created_date: "29/08/2023 02:14PM",
                updated_date: "21/02/2023 03:14PM",
                updated_by: "21/02/2023 03:14PM",
                created_by: "29/08/2023 05:54PM",
                created_time: "",
              },
            ],
            key: "CREATED AT",
          },
          last_modified_at: {
            value: [
              {
                createdBy_admin: "SUPER ADMIN",
                created_date: "18/03/2023 12:14PM",
                updated_date: "21/02/2023 03:14PM",
                created_by: "29/08/2023 05:54PM",
                updated_by: "21/02/2023 03:14PM",
              },
            ],
            key: "LAST MODIFIED AT",
          },
        },
      ],
      dragStartIndex: null,
      dragOverIndex: null,
      order: {
        id: null,
        date: format(new Date(), "yyyy-MM-dd"),
        time: format(new Date(), "HH:mm"),
        bill_no: null,
        amount: null,
        voucher: "none",
      },
    };
  },
  watch: {
    supplier_selectedAll(val) {
      this.salesSummaryDate.map((item) => (item.checkbox_status = val));
    },
    suppliertableHeading: {
      handler: function (newval) {
        console.log(newval);
      },
      deep: true,
    },
    currentPage() {
      this.getSummary();
    },
    listingSearch() {
      this.getSummary();
    },
    filterDateRange() {
      if (this.filterDateRange && this.filterDateRange.length > 1) {
        this.filterType = "all";
        this.getSummary();
      }
    },
  },
  methods: {
    updateVisible() {
      const _this = this;

      for (let index = 0; index < _this.defaultColDefs.length; index++) {
        const element = _this.defaultColDefs[index];
        _this.defaultColDefs[index].visible = 0;
        if (_this.defaultColShow.includes(element.field)) {
          _this.defaultColDefs[index].visible = 1;
        }
      }
      // console.log(this.defaultColDefs);

      _this.$nextTick(() => {
        _this.$store
          .dispatch(POST, {
            url: "group",
            data: { tables: this.defaultColDefs },
          })
          .then((data) => {
            this.customThead = data;
            // console.log(data)
          })
          .catch((error) => {
            _this.logError(error);
          })
          .finally(() => {
            _this.getSummary();
          });
      });
    },
    importExcel(){
      this.importDialog=true;

    },
    formatMoney(money) {
      return formatMoney(money, {
        symbol: "RM",
        decimal: ".",
        thousand: ",",
        precision: 2,
        format: "%s%v",
      });
    },
    formatedateTimes(param) {
      return moment(param).format("DD/MM/YYYY hh:mm A");
    },
    updateAddressPerson(param) {
      this.selectstatus = param;
      this.getSummary();
    },
    statusSelect(data) {
      this.selectstatus = data;
      this.getSummary();
    },
    getAvatarColor(value) {
      if (value && value == "all") {
        return "#0d47a1";
      } else if (value && value == "active") {
        return "green";
      } else if (value && value == "inactive") {
        return "red";
      }
    },
    getBgColor(value) {
      if (value && value == "all") {
        return "#90caf9";
      } else if (value && value == "active") {
        return "#6da16e";
      } else if (value && value == "inactive") {
        return "#c78a8a";
      }
    },
    search() {

      this.filterType = this.filter;
      this.filter_by_month = this.month;
      this.getSummary();
    },
    getColValue(val) {
      if (val.includes(" ")) {
        return val.split(" ").join("_").toLowerCase();
      } else {
        return val.toLowerCase();
      }
    },
    dragstart(index) {
      this.dragStartIndex = index;
    },
    dragover(index) {
      this.dragOverIndex = index;
    },
    dragEnd() {
      const dragStartElement = this.suppliertableHeading[this.dragStartIndex];
      this.suppliertableHeading.splice(this.dragStartIndex, 1);
      this.suppliertableHeading.splice(this.dragOverIndex, 0, dragStartElement);
    },
    drastart(index) {
      this.dragging_element_index = index;
    },
    filterTypeChange() {
      this.filterDateRange = null;
      // this.getSummary();
    },
    saveOrder() {
      this.customerId = null;
      this.order = {
        id: null,
        date: format(new Date(), "yyyy-MM-dd"),
        time: format(new Date(), "HH:mm"),
        bill_no: null,
        amount: null,
        voucher: "none",
      };
    },
    addOrder({ id }) {
      this.customerId = id;
      this.orderDialog = true;
    },
    vCollected() {
      this.cOrderId = 0;
      this.cOrderCode = null;
      this.cVouchers = [];
      this.collectDialog = false;
      this.saveOrder();
    },
    vRedeemed() {
      this.rOrderId = 0;
      this.rOrderCode = null;
      this.rVouchers = [];
      this.redeemDialog = false;
      this.saveOrder();
    },
    async getAvailableVouchers(orderId, barcode) {
      try {
        this.cOrderId = orderId;
        this.cOrderCode = barcode;
        this.cLoading = true;
        this.cVouchers = await GET_AVAILABLE_VOUCHERS(orderId);
        this.collectDialog = true;
      } catch (error) {
        this.$store.commit(SET_ERROR, [{ model: true, message: error }]);
      } finally {
        this.cLoading = false;
      }
    },
    async getCollectedVouchers(orderId, barcode) {
      try {
        this.rOrderId = orderId || 0;
        this.rOrderCode = barcode || null;
        this.rLoading = true;
        this.rVouchers = await GET_COLLECTED_VOUCHERS(this.customerId);
        this.redeemDialog = true;
      } catch (error) {
        this.$store.commit(SET_ERROR, [{ model: true, message: error }]);
      } finally {
        this.rLoading = false;
      }
    },
    routeToUpdate(id) {
      this.$router.push({
        name: "supplier-update",
        params: { id },
        query: { t: new Date().getTime() },
      });
    },
    // routeToDetailSupplier(id) {}
    routeToDetailSupplier(row) {
      this.$router.push({
        name: "supplier-detail",
        params: { id: row.id, row: row },
        query: { t: new Date().getTime() },
      });
    },
    deleteConfirm({ code, id }) {
      // const delete_row = this.salesSummaryDate[0].code;
      // console.log(delete_row, "delete_row");
      this.deleteText = code;
      this.deleteEndpoint = `supplier/delete/${id}`;
      this.deleteDialog = true;
    },
    async getSummary(search = null) {
      try {
        let form = {
          current_page: this.currentPage,
          search: this.listingSearch,
          filter_by_status: this.filterType,
          filter_by_month: this.filter_by_month,
          selectstatus: this.selectstatus,
          searchs: search
        };
        if (this.filterDateRange) {
          const dateRange = {
            start_date: this.filterDateRange[0],
            end_date: this.filterDateRange[1],
          };
          form = { ...form, ...dateRange };
        }
        this.pageLoading = true;
        const { rows, totalPages, showingFrom, showingTo, totalRows, status_list,theads } = await GET_CUSTOMERS(form);
        this.customers = rows;
        console.log(this.customers, "sfsfsdf");
        this.pageLimit = totalPages;
        this.showingFrom = showingFrom;
        this.showingTo = showingTo;
        this.totalRows = totalRows;
        this.status_list = status_list;
        let thead = theads;
        this.defaultColDefs = thead;
        this.customThead = [];
        for (let index = 0; index < thead.length; index++) {
          let element = thead[index];
          if (element.visible == 1) {
            this.customThead.push(element);
          }
        }

        let t_out = filter(thead, (row) => Number(row.visible) == 1);
        this.defaultColShow = map(t_out, "field");

        console.log(this.customThead);
      } catch (error) {
        this.$store.commit(SET_ERROR, [{ model: true, message: error }]);
      } finally {
        this.pageLoading = false;
      }
    },
    resetSearch() {
      this.$router
        .replace({
          query: {
            t: new Date().getTime(),
          },
        })
        .then(() => {
          this.$store.dispatch(SET_SEARCH_MODEL, null);
        });
    },
    async getTemplateListing() {
      try {
        this.pageLoading = true;
        console.log("getTemplateListing");
        const data = await GET_TEMPLATE_LIST();
        console.log("data", data);
      } catch (error) {
        this.$store.commit(SET_ERROR, [{ model: true, message: error }]);
      } finally {
        this.pageLoading = false;
      }
    },
  },
  async mounted() {
    let _this = this;
    SearchEventBus.$on("start-search", (argument) => {
      _this.searchEnabled = false;
      _this.searchParam = argument;
      if (_this.searchParam) {
        _this.searchEnabled = true;
      }
      this.getSummary(argument);
    });
    SearchEventBus.$emit("search-template", true);
    this.getSummary();
    // await this.getTemplateListing();
    this.$store.dispatch(SET_SEARCH_BAR, true);
    this.$store.dispatch(SET_SEARCH_TITLE, "Supplier");
  },
  beforeDestroy() {
    this.$store.dispatch(BEFORE_DESTROY);
  },
  components: {
    ImageTemplate,
    ValueTemplate,
    DeleteTemplate,
    OrderTemplate,
    CollectTemplate,
    SearchTemplate,
    SelectInput,
    Dialog,
    SupplierHeader,
    AutoCompleteInput,
    ImportTemplate
  },
  computed: {
    ...mapGetters(["listingSearch"]),
  },
};
</script>

<style>
.v-image.v-responsive.white.lighten-2.theme--light.border-light-grey.max-width-100px {
  height: 58px;
  width: 70px;
}

.advace_list_menu {
  border-bottom: 0.2px solid rgba(0, 0, 0, 0.158);
  display: flex;
  justify-content: space-between;
}

.table_height_supplier .v-data-table__wrapper {
  height: calc(100vh - 265px);
}

::-webkit-scrollbar {
  height: 10px;
}

.product-listing-tr:nth-child(even):hover {
  box-shadow: 0px 0px 10px #666666 !important;
  -webkit-box-shadow: 0px 0px 10px #666666 !important;
  -moz-box-shadow: 0px 0px 10px #666666 !important;
  background-color: #e3f2fd !important;
  position: relative;
  z-index: 5;
}

.product-listing-tr:nth-child(odd):hover {
  box-shadow: 0px 0px 10px #666666 !important;
  -webkit-box-shadow: 0px 0px 10px #666666 !important;
  -moz-box-shadow: 0px 0px 10px #666666 !important;
}
</style>
